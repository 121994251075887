import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';

import Logo from "../components/Logo";

const Index = () => {

  return (
      <>
        <Helmet>
          <title>Molinit.com</title>
        </Helmet>
        <main><Logo /></main>
      </>
  );
}

export default Index;
