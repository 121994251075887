import React from 'react';
const fill0 = { fill: '#06A34A' };
const fill1 = { fill: '#ffffff' };
const width = { s: '160px', m: '520px' };

const Logo = ({ size }) => (
    <div style={ { width: size ? width[size] : width.s } }>
        <svg version="1.1" id="Layer_1" viewBox="0 0 133.74 17.67">
        <g>
            <g>
                <polygon style={ fill0 } points="18.09,0 18.09,14.14 9.04,10.6 9.04,3.53 		"/>
                <polygon style={ fill1 } points="9.04,3.53 9.04,17.67 0,14.14 0,0 		"/>
            </g>
            <g>
                <path style={ fill1 } d="M56.54,2.98c-3.68,0-4.07,2.38-4.07,3.4v4.93c0,1.02,0.4,3.4,4.07,3.4s4.07-2.38,4.07-3.4V6.38
                    C60.62,5.35,60.22,2.98,56.54,2.98z M56.54,12.6c-1.97,0-1.97-0.81-1.97-1.3V6.38c0-0.49,0-1.3,1.97-1.3s1.97,0.81,1.97,1.3v4.93
                    C58.52,11.79,58.52,12.6,56.54,12.6z"/>
            </g>
            <g>
                <polygon style={ fill1 } points="86.78,2.98 84.68,2.98 84.68,14.7 86.78,13.87 		"/>
            </g>
            <polygon style={ fill1 } points="133.74,2.98 125.9,2.98 125.9,4.92 128.77,4.92 128.77,14.7 130.87,13.87 130.87,4.92 133.74,4.92
                "/>
            <g>
                <polygon style={ fill1 } points="116.1,2.98 114,2.98 114,14.7 116.1,13.87 		"/>
            </g>
            <g>
                <polygon style={ fill1 } points="70.42,12.6 70.42,2.98 68.32,3.81 68.32,12.6 68.32,14.7 68.32,14.7 70.42,14.7 70.42,14.7
                    75.58,14.7 75.58,12.6 		"/>
            </g>
            <polygon style={ fill1 } points="34.72,14.7 36.82,13.87 36.82,7.43 39.75,13.63 42.67,7.71 42.67,14.7 44.77,14.7 44.77,2.98
                42.67,2.98 39.75,8.89 36.82,2.98 34.72,2.98 	"/>
            <g>
                <polygon style={ fill1 } points="102.1,2.98 102.1,9.89 98.68,2.98 96.58,2.98 96.58,14.7 98.68,13.87 98.68,7.71 102.1,14.62
                    102.14,14.7 104.2,14.7 104.2,14.13 104.2,2.98 		"/>
            </g>
        </g>
        </svg>
    </div>
);

export default Logo;